import React from 'react';

export default function usePromptToHome() {
  const [promptEvent, setPromptEvent] = React.useState(null);
  const [hasUserAccepted, setHasUserAccepted] = React.useState(false);
  function promptDialog() {
    if (!promptEvent) {
      return;
    }

    promptEvent.prompt();
    promptEvent.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        setHasUserAccepted(true);
      } else {
        setHasUserAccepted(false);
      }
    });
  }
  React.useEffect(() => {
    const callbackFunction = e => {
      e.preventDefault();
      setPromptEvent(e);
    };
    window.addEventListener('beforeinstallprompt', callbackFunction);
    return () => {
      window.removeEventListener('beforeinstallprompt', callbackFunction);
    };
  }, []);
  return { promptEvent, promptDialog, hasUserAccepted };
}
