import React from 'react';
import isStringChinese from './isStringChinese.js';

/*
 * Filterd book list with keyword
 */

export default function useFilteredBookList(keywordString, bookList) {
  const [filteredBookList, setFilteredBookList] = React.useState([]);
  React.useEffect(() => {
    const filteredBookList = bookList.filter(book => {
      const keywordsRegexString = keywordString
        .toLowerCase()
        .replace(/\s/g, '')
        .split('')
        .join('.*');
      const keywordRegex = new RegExp(`${keywordsRegexString}`, 'g');
      if (isStringChinese(keywordString)) {
        return keywordRegex.test(book.cname.toLowerCase());
      } else {
        return keywordRegex.test(book.name.toLowerCase());
      }
    });
    setFilteredBookList(filteredBookList);
  }, [keywordString, bookList]);
  return [filteredBookList];
}
