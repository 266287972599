import { BackButton, Toolbar } from 'react-onsenui';
import React from 'react';

import './StaticHeader.css';

function StaticHeader({ title, onBackButton }) {
  return (
    <Toolbar className="static-header">
      {onBackButton ? (
        <div className="left">
          <BackButton onClick={onBackButton} />
        </div>
      ) : null}
      <div className="app-header-title center">{title}</div>
    </Toolbar>
  );
}

export default StaticHeader;
