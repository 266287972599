import React, { createContext, useState, useCallback } from 'react';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const toastContext = {
    isOpen,
    message,
    openToast: useCallback((message) => {
      setMessage(message);
      setIsOpen(true);
    }, []),
    hideToast: useCallback(() => setIsOpen(false), []),
    setMessage: useCallback((message) => {
      setMessage(message);
    }, []),
  };
  return <Context.Provider value={toastContext}>{children}</Context.Provider>;
};
