import React from 'react';
import useBookList from './useBookList';

const BOOK_TOTAL_SECTIONS_AMOUNT = 1189;
const BOOK_OLD_TESTAMENT_SECTIONS_AMOUNT = 929;
const BOOK_NEW_TESTAMENT_SECTIONS_AMOUNT = 260;

function getSingleRecordAmount(singleRecord) {
  return Object.values(singleRecord).filter((value) => value).length;
}

function getPartialAmount(records, bookList) {
  return Object.keys(records).reduce(
    (acc, bookName) => {
      const targetBook =
        bookList &&
        bookList.find &&
        bookList.find((book) => {
          return book.shortName === bookName;
        });
      if (!targetBook) {
        return acc;
      }
      const singleRecord = records[bookName];
      if (targetBook.order > 39) {
        return {
          ...acc,
          newTestamentAmount:
            acc.newTestamentAmount + getSingleRecordAmount(singleRecord),
        };
      } else {
        return {
          ...acc,
          oldTestamentAmount:
            acc.oldTestamentAmount + getSingleRecordAmount(singleRecord),
        };
      }
    },
    {
      newTestamentAmount: 0,
      oldTestamentAmount: 0,
    }
  );
}

function valueToPercentage(value, totalValue) {
  return Math.round((value / totalValue) * 10000) / 100;
}

function useProgress(records) {
  const [bookList] = useBookList();
  const [totalProgress, setTotalProgress] = React.useState(0);
  const [newTestamentProgress, setNewTestamentProgress] = React.useState(0);
  const [oldTestamentProgress, setOldTestamentProgress] = React.useState(0);
  React.useEffect(() => {
    if (records) {
      const myRecordTotalAmount = Object.values(records).reduce(
        (acc, singleRecord) => {
          if (!singleRecord) {
            return acc;
          }

          return acc + getSingleRecordAmount(singleRecord);
        },
        0
      );
      const partialProgress = getPartialAmount(records, bookList);
      const totalProgress = valueToPercentage(
        myRecordTotalAmount,
        BOOK_TOTAL_SECTIONS_AMOUNT
      );

      const newTestamentProgress = valueToPercentage(
        partialProgress.newTestamentAmount,
        BOOK_NEW_TESTAMENT_SECTIONS_AMOUNT
      );

      const oldTestamentProgress = valueToPercentage(
        partialProgress.oldTestamentAmount,
        BOOK_OLD_TESTAMENT_SECTIONS_AMOUNT
      );

      setTotalProgress(totalProgress);
      setOldTestamentProgress(oldTestamentProgress);
      setNewTestamentProgress(newTestamentProgress);
    }
  }, [bookList, records]);
  return [totalProgress, oldTestamentProgress, newTestamentProgress];
}

export default useProgress;
