import React from 'react';

export default function useInput(initValue = '') {
  const [value, setValue] = React.useState(initValue);
  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      }
    }
  };
}
