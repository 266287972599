import React, { useEffect, useState } from 'react';

import BookRecordEntry from './BookRecordEntry';
import useLocalStorage from '../utils/useLocalStorage';
import './BookRecord.css';

export default function BookRecord({
  book = {},
  record = {},
  onClick,
  clickAllRecord,
  unClickAllRecord,
  pushPage,
}) {
  const [, setBookAndSection] = useState({});
  const [useRecommendProgress] = useLocalStorage(
    'bt:use-recommend-progress',
    false
  );
  const [shouldShowBibleContent] = useLocalStorage(
    'bt:show-bible-content',
    false
  );
  const [progress, setProgress] = useState({});
  useEffect(() => {
    if (useRecommendProgress && book && book.shortName) {
      fetch('/progress2022.json')
        .then((res) => res.json())
        .then((data) => {
          if (data[book.shortName]) {
            setProgress(data[book.shortName]);
          }
        });
    }
  }, [book]);

  if (!book) {
    return null;
  }
  let bookEntries = [];
  for (let i = 1; i <= book.amount; ++i) {
    bookEntries.push(
      <BookRecordEntry
        key={`${book.name}-${i}`}
        shortName={book.shortName}
        bookCname={book.cname}
        index={i}
        hasRead={record[i]}
        progress={progress[i - 1]}
        pushPage={pushPage}
        onClick={onClick}
        onBookReadButtonClick={setBookAndSection}
        shouldShowBibleContent={shouldShowBibleContent}
      />
    );
  }
  return (
    <section>
      <div className="book-record">{bookEntries}</div>
      <div className="book-record-tool-bar">
        <button className="book-record-tool-btn" onClick={clickAllRecord}>
          全部選取
        </button>
        <button className="book-record-tool-btn" onClick={unClickAllRecord}>
          全部取消選取
        </button>
      </div>
    </section>
  );
}
