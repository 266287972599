import React, { useCallback, useEffect, useState } from 'react';

/* global ga */

import { Page, Tabbar, Tab } from 'react-onsenui';

import MobileBookListPage from './MobileBookListPage';
import HomePage from './HomePage';
import MobileUserSettingPage from './MobileUserSettingPage';
import ToastMessage from '../components/ToastMessage';
import sendBeacon from '../utils/sendBeacon';

function sendEventBeacon(index) {
  if (!ga) {
    return;
  }
  ga(() => {
    const allTracker = ga.getAll();
    if (!allTracker || allTracker.length < 1) {
      return;
    }
    const tracker = allTracker[0];
    tracker.send({
      hitType: 'event',
      eventCategory: 'tabOnTabbar',
      eventAction: 'click',
      eventLabel: `index-${index}`,
    });
  });
}

function MobileMainPage() {
  const [index, setIndex] = useState(0);
  const sendPageView = useCallback((event) => {
    const { activeIndex } = event.detail;
    let pageviewString = null;
    if (activeIndex == 1) {
      pageviewString = 'record page';
    } else if (activeIndex == 2) {
      pageviewString = 'user setting page';
    } else {
      pageviewString = 'main page';
    }
    sendBeacon('pageview', {
      name: 'page',
      content: pageviewString,
    });
  });

  useEffect(() => {
    sendBeacon('pageview', {
      name: 'page',
      content: 'main page',
    });
  }, []);

  return (
    <Page>
      <Tabbar
        tabBorder={true}
        swipeable={true}
        onPreChange={({ index }) => {
          setIndex(index);
          sendEventBeacon(index);
        }}
        onPostChange={sendPageView}
        onReactive={() => console.log('postChange')}
        position="bottom"
        index={index}
        renderTabs={(activeIndex, tabbar) => [
          {
            content: (
              <HomePage
                title="Home"
                active={activeIndex === 0}
                tabbar={tabbar}
                swipeToIndex={setIndex}
              />
            ),
            tab: <Tab key="tab-1" label="Home" icon="fa-home" />,
          },
          {
            content: (
              <MobileBookListPage
                title="BookList"
                active={activeIndex === 1}
                tabbar={tabbar}
              />
            ),
            tab: <Tab key="tab-2" label="Record" icon="fa-edit" />,
          },
          {
            content: (
              <MobileUserSettingPage
                title="Settings"
                active={activeIndex === 2}
                tabbar={tabbar}
              />
            ),
            tab: <Tab key="tab-3" label="Settings" icon="md-settings" />,
          },
        ]}
      />
      <ToastMessage />
    </Page>
  );
}

export default MobileMainPage;
