import React, { useState, useCallback } from 'react';
import useFrequency from '../utils/useFrequency';
import isIOSSafari from '../utils/isIOSSafari';
import './AddToHomeIOS.css';

export default function AddToHomeIOS() {
  const [shouldShow, setShouldShow] = useState(true);
  const [isOverFrequency, resetTime] = useFrequency();

  const onClick = useCallback(() => {
    setShouldShow(false);
    resetTime();
  }, []);

  if (!isIOSSafari() || !shouldShow || !isOverFrequency) {
    return null;
  }
  return (
    <div className="add-to-home-container" onClick={onClick}>
      <div className="add-to-home">
        <div className="click-here-to-install-ios-app">
          <span className="click-here-to-install-ios-app-text">
            按下面圖示安裝這個App
          </span>
          <img
            width="20px"
            alt="ios more behavior icon"
            src="/ios-more-behavior.png"
          ></img>
        </div>
        <div className="click-here-to-install-ios-app">
          並選取「加入主畫面」
        </div>
      </div>
    </div>
  );
}
