import React from 'react';

export default function useLocalStorage(key, initValue) {
  const [value, setValue] = React.useState(() => {
    try {
      const stateInitValue = localStorage.getItem(key) || initValue;
      return stateInitValue ? JSON.parse(stateInitValue) : initValue;
    } catch (err) {
      if (err instanceof SyntaxError) {
        console.info('init storage');
      } else {
        console.error('useLocalStorage', err);
      }
      return initValue;
    }
  });
  React.useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {}
  }, [key, value]);
  return [value, setValue];
}
