import React from 'react';
import getTodayProgress from './getTodayProgress';

function useTodayProgress() {
  const [todayProgressInCName, setTodayProgressInCName] = React.useState([]);
  React.useEffect(() => {
    Promise.all([
      fetch('/progress2022.json').then((res) => res.json()),
      fetch('/book-list.json').then((res) => res.json()),
    ]).then(([progress, bookList]) => {
      const todayProgress = getTodayProgress(progress);
      const todayProgressInCName = todayProgress.map(
        ([bookShortName, ...rest]) => {
          const matchedBook = bookList.find((book) => {
            if (book.shortName === bookShortName) {
              return true;
            }
            return false;
          });
          return [matchedBook.cname, ...rest];
        }
      );
      setTodayProgressInCName(todayProgressInCName);
    });
  }, []);
  return [todayProgressInCName];
}

export default useTodayProgress;
