import React, { Component } from 'react';

import { RouterNavigator, RouterUtil } from 'react-onsenui';

import BookListPage from './BookListPage';

// const MyToolbar = ({ title, onBackButton = null }) => (
//   <Toolbar>
//     {onBackButton ? (
//       <div className="left">
//         <BackButton onClick={onBackButton} />
//       </div>
//     ) : null}
//     <div className="center">{title}</div>
//   </Toolbar>
// );

// const ThirdPage = ({ popPage }) => {
//   return (
//     <Page
//       renderToolbar={() => (
//         <MyToolbar title="Third page" onBackButton={popPage} />
//       )}
//     >
//       <div
//         style={{
//           textAlign: 'center',
//           margin: 10,
//         }}
//       >
//         <Button onClick={popPage}>Pop page</Button>
//       </div>
//     </Page>
//   );
// };

// const SecondPage = ({ pushPage, popPage }) => {
//   return (
//     <Page
//       renderToolbar={() => (
//         <MyToolbar title="Second page" onBackButton={popPage} />
//       )}
//     >
//       <div
//         style={{
//           textAlign: 'center',
//           margin: 10,
//         }}
//       >
//         <Button onClick={() => pushPage(ThirdPage, 'third')}>Push page</Button>
//         <Button onClick={popPage}>Pop page</Button>
//       </div>
//     </Page>
//   );
// };

// const MainPage = ({ pushPage }) => {
//   return (
//     <Page renderToolbar={() => <MyToolbar title="Main page" />}>
//       <div
//         style={{
//           textAlign: 'center',
//           margin: 10,
//         }}
//       >
//         <Button onClick={() => pushPage(SecondPage, 'second')}>
//           Push page
//         </Button>
//       </div>
//     </Page>
//   );
// };

export default class extends Component {
  constructor(props) {
    super(props);

    const routeConfig = RouterUtil.init([
      {
        component: BookListPage,
        props: {
          key: 'main',
          pushPage: (...args) => this.pushPage(...args),
        },
      },
    ]);

    this.state = { routeConfig };
  }

  pushPage(page, key, props) {
    const route = {
      component: page,
      props: {
        key,
        ...props,
        popPage: () => this.popPage(),
        pushPage: (...args) => this.pushPage(...args),
      },
    };

    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.push({
      routeConfig,
      route,
    });

    this.setState({ routeConfig });
  }

  popPage(options = {}) {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.pop({
      routeConfig,
      options: {
        ...options,
        animationOptions: { duration: 0.4 },
      },
    });

    this.setState({ routeConfig });
  }

  onPostPush() {
    const routeConfig = RouterUtil.postPush(this.state.routeConfig);
    this.setState({ routeConfig });
  }

  onPostPop() {
    const routeConfig = RouterUtil.postPop(this.state.routeConfig);
    this.setState({ routeConfig });
  }

  renderPage(route) {
    const props = route.props || {};
    return <route.component {...props} />;
  }

  render() {
    return (
      <RouterNavigator
        swipeable={true}
        swipePop={(options) => this.popPage(options)}
        routeConfig={this.state.routeConfig}
        renderPage={this.renderPage}
        onPostPush={() => this.onPostPush()}
        onPostPop={() => this.onPostPop()}
      />
    );
  }
}
