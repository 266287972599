import React, { useEffect } from 'react';

import useInput from '../utils/useInput.js';
import './SearchBar.css';

export default function SearchBar({ searchKeyWordCallback }) {
  const { value, bind } = useInput('');

  useEffect(() => {
    searchKeyWordCallback && searchKeyWordCallback(value);
  }, [value, searchKeyWordCallback]);

  return (
    <div className="search-bar">
      <div className="inner-search-bar-container">
        <img alt="search icon" width="14pt" src="search-icon.png" />
        <input
          type="search"
          className="inner-search-bar"
          placeholder="搜尋書卷 (中英皆可)"
          {...bind}
        ></input>
      </div>
    </div>
  );
}
