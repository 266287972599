import React from 'react';

export default function useBookList() {
  const [bookList, setBookList] = React.useState([]);
  const controller = new AbortController();
  const signal = controller.signal;
  React.useEffect(() => {
    fetch('/book-list.json', { signal })
      .then((res) => res.json())
      .then((fetchedBookList) => {
        setBookList(fetchedBookList);
      })
      .catch((err) => {
        console.log('[fetch book list]', err);
      });
    return () => {
      controller.abort();
    };
  }, []);
  return [bookList];
}
