import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { Toast } from 'react-onsenui';

import { Context as ToastContext } from '../context/ToastContext';

const TOAST_SHOWING_TIME = 1500;

function ToastMessage() {
  const { isOpen, message, hideToast } = useContext(ToastContext);
  const [isShowing, setIsShowing] = useState(false);
  const timerToClear = useRef(null);

  useEffect(() => {
    if (isShowing) {
      clearTimeout(timerToClear.current);
      timerToClear.current = setTimeout(() => {
        hideToast();
      }, TOAST_SHOWING_TIME);
    }
    return () => {
      if (isShowing) {
        clearTimeout(timerToClear.current);
        hideToast();
      }
    };
  }, [isShowing, hideToast]);

  const closeToast = useCallback(() => {
    hideToast();
  }, []);
  return (
    <Toast
      isOpen={isOpen}
      onPostShow={() => {
        setIsShowing(true);
      }}
      onPostHide={() => {
        setIsShowing(false);
      }}
    >
      <div className="message" onClick={closeToast}>
        {message}
      </div>
    </Toast>
  );
}
export default ToastMessage;
