const ONE_DAY = 60 * 60 * 24 * 1000;

export default function getTodayProgress(progress) {
  const now = Date.now();
  const result = Object.keys(progress).reduce((acc, cur) => {
    const indexes = progress[cur].reduce((acc, cur, index) => {
      const time = new Date(cur).getTime();
      if (now >= time && now < time + ONE_DAY) {
        return [...acc, index + 1];
      }
      return acc;
    }, []);
    if (indexes.length > 0) {
      return [...acc, [cur, ...indexes]];
    }
    return acc;
  }, []);
  return result;
}
