import useLocalStorage from './useLocalStorage';
import { useCallback } from 'react';

export default function useDataBase() {
  const [value, setValue] = useLocalStorage('my-record', {
    records: {},
    recordsHistory: {},
    userProfile: {},
  });
  const [history, setHistory] = useLocalStorage('bible-traveler-history', {});
  const setRecords = useCallback(
    (records) => {
      setValue({
        ...value,
        records: {
          ...value.records,
          ...records,
        },
      });
    },
    [value, setValue]
  );
  const clearAllRecords = useCallback(() => {
    setValue({
      records: {},
      recordsHistory: {},
      userProfile: {},
    });
  }, [setValue]);
  const setRecordsHistory = useCallback(
    (historyEntry, date) => {
      let targetDate = date;
      const currentTime = new Date();
      if (!targetDate) {
        targetDate = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate()
        ).getTime();
      }
      const currentHistory = history[targetDate] || [];
      setHistory({
        [targetDate]: [
          ...currentHistory,
          { ...historyEntry, createTime: currentTime.getTime() },
        ],
      });
    },
    [history, setHistory]
  );
  return {
    clearAllRecords,
    setUserProfile: (userProfile) => {
      setValue({
        ...value,
        userProfile: {
          ...value.userProfile,
          ...userProfile,
        },
      });
    },
    setRecords,
    setRecordsHistory,
    getUserProfile: () => {
      return value.userProfile;
    },
    records: value.records,
    getRecordsHistory: (date) => {
      if (date) {
        return value.recordsHistory[date] || [];
      }
      return value.recordsHistory;
    },
  };
}
