import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Toolbar, BackButton, ToolbarButton, Toast } from 'react-onsenui';

// import Hero from '../components/Hero.js';
import { Page } from 'react-onsenui';
import BookRecord from '../components/BookRecord.js';
import useBookList from '../utils/useBookList';
import { Context as HeaderContext } from '../context/headerContext';
import { Context as RecordContext } from '../context/recordContext';
import { Context as ToastContext } from '../context/ToastContext';
import './BookPage.css';
import sendBeacon from '../utils/sendBeacon.js';

function useInitBookPage(bookName, bookShortName, saveToLocalStorageFunction) {
  const headerContext = useContext(HeaderContext);
  const {
    records,
    setRecords: setRecordsToLocalStorage,
    setRecordsHistory,
  } = useContext(RecordContext);
  const initState = React.useRef({}).current;
  const recordFromLocalStorage = records[bookShortName] || initState;
  const [recordState, setRecordState] = useState(recordFromLocalStorage || {});
  const {
    setToFeatured,
    setTitle,
    setRightBehavior,
    setLeftBehavior,
    setRightBehaviorActive,
    rightBehaviorActive,
  } = headerContext;
  useEffect(() => {
    // update data from local storage to state
    setRecordState(recordFromLocalStorage);
  }, [recordFromLocalStorage, setRecordState]);
  const saveDataToLocalStorage = useCallback(() => {
    saveToLocalStorageFunction(
      bookShortName,
      recordFromLocalStorage,
      records,
      recordState,
      rightBehaviorActive,
      setRecordsHistory,
      setRecordsToLocalStorage,
      setRightBehaviorActive
    );
  }, [
    bookShortName,
    recordFromLocalStorage,
    records,
    recordState,
    rightBehaviorActive,
    setRecordsHistory,
    setRecordsToLocalStorage,
    setRightBehaviorActive,
  ]);
  useEffect(() => {
    setTitle && setTitle(bookName);
    setToFeatured && setToFeatured();
  }, [
    bookName,
    bookShortName,
    recordFromLocalStorage,
    records,
    recordState,
    rightBehaviorActive,
    saveToLocalStorageFunction,
    setLeftBehavior,
    setRecordsHistory,
    setRecordsToLocalStorage,
    setRightBehavior,
    setRightBehaviorActive,
    setTitle,
    setToFeatured,
  ]);
  return [
    recordState,
    setRecordState,
    setRightBehaviorActive,
    saveDataToLocalStorage,
  ];
}

function BookPageHeader({
  onBackButton,
  title,
  saveFunction,
  shouldHighlightSaveButton,
}) {
  return (
    <Toolbar>
      {onBackButton ? (
        <div className="left">
          <BackButton onClick={onBackButton} />
        </div>
      ) : null}
      <div className="book-page-title center">{title}</div>
      <div className="right">
        <ToolbarButton onClick={saveFunction}>
          <span
            className={
              shouldHighlightSaveButton ? 'highlight-btn' : 'lowlight-btn'
            }
          >
            儲存
          </span>
        </ToolbarButton>
      </div>
    </Toolbar>
  );
}

export default function BookPage({ bookId, popPage, pushPage }) {
  const [bookList] = useBookList();
  const [recordHistory, setRecordHistory] = useState({});
  const { openToast } = useContext(ToastContext);
  const [highlightSaveButton, setHighlightSaveButton] = useState(false);

  const book =
    bookList.find((book) => {
      return book.shortName === bookId;
    }) || {};

  const saveFunction = useCallback(
    (
      bookShortName,
      recordFromLocalStorage,
      recordsFromLocalStorage,
      recordState,
      rightBehaviorActive,
      setRecordsHistory,
      setRecordsToLocalStorage
    ) => {
      if (!highlightSaveButton) {
        return;
      }
      // Save to localStorage
      setRecordsToLocalStorage({
        ...recordsFromLocalStorage,
        [bookShortName]: {
          ...recordFromLocalStorage,
          ...recordState,
        },
      });
      setRecordsHistory(recordHistory);
      setHighlightSaveButton(false);
      openToast('儲存成功！');
    },
    [recordHistory, highlightSaveButton]
  );

  const [
    recordState,
    setRecordState,
    setRightBehaviorActive,
    saveDataToLocalStorage,
  ] = useInitBookPage(book.cname, book.shortName, saveFunction);

  useEffect(() => {
    sendBeacon('pageview', {
      name: 'page',
      content: `book-page:${bookId}`,
    });
  }, []);

  return (
    <Page
      renderToolbar={() => (
        <BookPageHeader
          onBackButton={popPage}
          title={book.cname}
          saveFunction={saveDataToLocalStorage}
          shouldHighlightSaveButton={highlightSaveButton}
        />
      )}
    >
      <section className="book-page">
        <BookRecord
          pushPage={pushPage}
          book={book}
          record={recordState}
          setRecordState={setRecordState}
          onClick={(index) => {
            // Highlight `save` button
            // setRightBehaviorActive(true);
            setHighlightSaveButton(true);

            setRecordHistory({
              ...recordHistory,
              [index]: !recordState[index],
            });
            setRecordState({ ...recordState, [index]: !recordState[index] });
          }}
          clickAllRecord={() => {
            // Highlight `save` button
            setHighlightSaveButton(true);
            let records = {};
            for (let i = 1; i <= book.amount; ++i) {
              records[i] = true;
            }
            setRecordHistory(records);
            setRecordState(records);
          }}
          unClickAllRecord={() => {
            // Highlight `save` button
            setHighlightSaveButton(true);
            let records = {};
            for (let i = 1; i <= book.amount; ++i) {
              records[i] = false;
            }
            setRecordHistory(records);
            setRecordState(records);
          }}
        />
      </section>
    </Page>
  );
}
