/* global ga, gapi */

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Switch, Page, Button, AlertDialog } from 'react-onsenui';
import AWS from 'aws-sdk';

import StaticHeader from '../components/Mobile/StaticHeader';
import { Context as HeaderContext } from '../context/headerContext';
import { Context as UserContext } from '../context/UserContext';
import useLocalStorage from '../utils/useLocalStorage';
import useDataBase from '../utils/useDataBase';
import './UserSettingPage.css';

function sendEventBeacon(name, onOrOff) {
  if (!ga) {
    return;
  }
  ga(() => {
    const allTracker = ga.getAll();
    if (!allTracker || allTracker.length < 1) {
      return;
    }
    const tracker = allTracker[0];
    tracker.send({
      hitType: 'event',
      eventCategory: 'switch',
      eventAction: 'click',
      eventLabel: `${name}-${onOrOff}`,
    });
  });
}

function Login({ isSignedIn }) {
  const signIn = useCallback(() => {
    gapi.auth2.getAuthInstance().signIn();
  });

  const signOut = useCallback(() => {
    gapi.auth2.getAuthInstance().signOut();
    AWS.config.credentials?.clearCachedId();
    AWS.config.credentials = null;
  });
  if (!isSignedIn) {
    return (
      <ul>
        <img
          onClick={signIn}
          srcSet="icons/btn_google_signin_light_normal_web.png,
          icons/btn_google_signin_light_normal_web@2x.png 2x"
          src="icons/btn_google_signin_light_normal_web@2x.png"
        />
      </ul>
    );
  } else {
    return (
      <ul>
        已登入，
        <Button modifier="quiet" onClick={signOut}>
          按這裡登出
        </Button>
      </ul>
    );
  }
}

export default function UserSettingPage() {
  const [showRecommendProgress, setShowRecommendProgress] = useLocalStorage(
    'bt:use-recommend-progress',
    false
  );
  const [haveReadTimes, setHaveReadTimes] = useLocalStorage(
    'bt:have-read-times',
    0
  );
  const [isProgressChecked, setIsProgressChecked] = useState(
    showRecommendProgress
  );
  const [showBibleContent, setShowBibleContent] = useLocalStorage(
    'bt:show-bible-content',
    false
  );
  const [isBibleContentChecked, setIsBibleContentChecked] =
    useState(showBibleContent);
  const dataBase = useDataBase();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const headerContext = useContext(HeaderContext);
  const userContext = useContext(UserContext);
  const { setToNormal, resetTitle } = headerContext;
  useEffect(() => {
    setShowRecommendProgress(isProgressChecked);
  }, [isProgressChecked, setShowRecommendProgress]);
  useEffect(() => {
    setShowBibleContent(isBibleContentChecked);
  }, [isBibleContentChecked, setShowBibleContent]);
  useEffect(() => {
    resetTitle && resetTitle();
    setToNormal && setToNormal();
  }, [setToNormal, resetTitle]);

  return (
    <Page renderToolbar={() => <StaticHeader title="使用者設定" />}>
      <div className="user-setting-page">
        <ul>
          <li className="recommend-progress-entry">
            顯示推薦進度
            <Switch
              onChange={(event) => {
                setIsProgressChecked(event.target.checked);
                sendEventBeacon('recommend-progress', event.target.checked);
              }}
              checked={isProgressChecked}
            ></Switch>
          </li>
        </ul>
        <ul>
          <li className="recommend-progress-entry">
            <label htmlFor="read-book-content">開啟閱讀經文功能</label>
            <Switch
              inputId="read-book-content"
              disabled={!userContext.isSignedIn}
              onChange={(event) => {
                setIsBibleContentChecked(event.target.checked);
                sendEventBeacon('read-book-content', event.target.checked);
              }}
              checked={isBibleContentChecked}
            ></Switch>
          </li>
        </ul>
        <Login isSignedIn={userContext?.isSignedIn} />
        <div className="read-once">
          讀完一遍了嗎？
          <Button modifier="quiet" onClick={() => setIsDialogOpen(true)}>
            按這裡
          </Button>
        </div>
        {haveReadTimes ? (
          <div className="read-once">我目前已經讀完了 {haveReadTimes} 遍！</div>
        ) : null}
      </div>
      <AlertDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        isCancelable={false}
      >
        <div className="alert-dialog-title">恭喜！</div>
        <div className="alert-dialog-content">
          您讀完一遍了嗎？按下「確認」，將會清空您的讀經紀錄，並在您的讀完一遍紀錄上加一。
        </div>
        <div className="alert-dialog-footer">
          <Button
            onClick={() => setIsDialogOpen(false)}
            className="alert-dialog-button"
          >
            先不要
          </Button>
          <Button
            onClick={() => {
              setHaveReadTimes(haveReadTimes + 1);
              dataBase.clearAllRecords();
              window.location.reload();
            }}
            className="alert-dialog-button"
          >
            確認
          </Button>
        </div>
      </AlertDialog>
    </Page>
  );
}
