import React, { createContext, useState, useCallback } from 'react';

export const Context = createContext({});
export const TYPE = {
  NORMAL: 'NORMAL',
  FEATURED: 'FEATURED'
};

const INIT_TITLE = 'Bible Traveler';

export const Provider = ({
  children,
  initType = TYPE.NORMAL,
  initTitle = INIT_TITLE
}) => {
  const [type, setType] = useState(initType);
  const [title, setTitle] = useState(initTitle);
  const [leftBehavior, setLeftBehavior] = useState({});
  const [rightBehavior, setRightBehavior] = useState({});
  const [rightBehaviorActive, setRightBehaviorActive] = useState(false);
  const headerContext = {
    leftBehavior,
    resetTitle: useCallback(() => {
      setTitle(INIT_TITLE);
    }, []),
    rightBehavior,
    rightBehaviorActive,
    setLeftBehavior,
    setRightBehavior: useCallback((...props) => {
      setRightBehavior(...props);
    }, []),
    setTitle: useCallback(title => {
      setTitle(title);
    }, []),
    setToFeatured: useCallback(() => {
      setType(TYPE.FEATURED);
    }, []),
    setToNormal: useCallback(() => {
      setType(TYPE.NORMAL);
    }, []),
    setRightBehaviorActive: useCallback(isActive => {
      setRightBehaviorActive(isActive);
    }, []),
    title,
    type
  };
  return <Context.Provider value={headerContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
