import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';

import usePromptToHome from '../utils/usePromptToHome';
import useFrequency from '../utils/useFrequency';
import isIOSSafari from '../utils/isIOSSafari';

import './AddToHomeAndroid.css';

export default function AddToHomeAndroid() {
  const { promptEvent, promptDialog } = usePromptToHome();
  const [isModalOpendOnce, setIsModalOpenedOnce] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOverFrequency, resetTime] = useFrequency();
  function closeModal() {
    setIsModalOpen(false);
    resetTime();
  }
  function clickOK(e) {
    promptDialog(e);
    setIsModalOpen(false);
    resetTime();
  }
  useEffect(() => {
    if (promptEvent && !isModalOpendOnce) {
      setIsModalOpen(true);
      setIsModalOpenedOnce(true);
    }
  }, [promptEvent]);
  if (!promptEvent || isIOSSafari() || !isOverFrequency) {
    return null;
  }
  return (
    <ReactModal
      isOpen={isModalOpen}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1 },
        content: { top: 'initial' }
      }}
    >
      <div className="add-to-home-android">
        <div className="add-to-home-android-title">安裝這個 App</div>
        <div className="add-to-home-android-body">
          <img alt="app-icon" src="/bible-icon64.png" width="64px"></img>
          <div className="add-to-home-android-app-title">
            <div className="add-to-home-android-app-title-main">
              Bible Traveler
            </div>
            <div className="add-to-home-android-app-title-sub">
              www.pocket-bible.com
            </div>
          </div>
        </div>
        <div className="add-to-home-android-footer">
          <button
            className="add-to-home-android-footer-btn MStart-12"
            onClick={closeModal}
          >
            取消
          </button>
          <button className="add-to-home-android-footer-btn" onClick={clickOK}>
            接受
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
