import React, { useEffect, useState } from 'react';
import { Page, ProgressCircular } from 'react-onsenui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';
import AWS from 'aws-sdk';

import StaticHeader from '../components/Mobile/StaticHeader';
import bookNameMapping from '../configs/bookNameMapping';
import './BookContentPage.css';

function FetchBibleFailureContent() {
  return (
    <div className="fetch-bible-failure">
      <FontAwesomeIcon
        icon={faSadTear}
        size="2x"
        style={{ marginBottom: '10px' }}
      />
      很抱歉，似乎出現了問題
    </div>
  );
}

function BookContentPage({ bookName, bookCname, sectionIndex, popPage }) {
  const [contentComponent, setContentComponent] = useState(
    <ProgressCircular indeterminate={true} value={100} />
  );
  useEffect(() => {
    // Some book will not get data correctly from 3rd party api
    const targetBookName = bookNameMapping.get(bookName)
      ? bookNameMapping.get(bookName)
      : bookName;
    const lambda = new AWS.Lambda();
    const params = {
      FunctionName: 'bible-content',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({
        pathParameters: {
          book: targetBookName,
          chapter: sectionIndex,
        },
      }),
    };
    lambda.invoke(params, function (err, data) {
      if (err) {
        console.error('lambda fetch', err);
        setContentComponent(<FetchBibleFailureContent />);
      } else {
        let content = {};
        try {
          const payload = JSON.parse(data.Payload);
          content = JSON.parse(payload.body);
        } catch (parseError) {
          setContentComponent(<FetchBibleFailureContent />);
          throw parseError;
        }
        let verses = [];
        for (let [, value] of Object.entries(content.chapter)) {
          const { verse_nr: verseNumber, verse: verseContent } = value;
          verses.push(
            <span key={`verse-number-${verseNumber}`} className="verse-number">
              {verseNumber}
            </span>
          );
          verses.push(
            <span
              key={`verse-content-${verseNumber}`}
              className="verse-content"
            >
              {verseContent}
            </span>
          );
        }
        setContentComponent(<div>{verses}</div>);
      }
    });
  }, [bookName, sectionIndex]);
  return (
    <Page
      renderToolbar={() => (
        <StaticHeader
          title={`[閱讀] ${bookCname}:${sectionIndex}`}
          onBackButton={popPage}
        />
      )}
    >
      <div className="page-content">{contentComponent}</div>
    </Page>
  );
}

export default BookContentPage;
