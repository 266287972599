import React from 'react';
import './ProgressBar.css';

export const ProgressSection = ({ percentage }) => {
  return (
    <div className="progress-section">
      <div className="progress-section-percentage">{percentage}%</div>
      <ProgressBar percentage={percentage} />
    </div>
  );
};

export default function ProgressBar({ percentage }) {
  if (typeof percentage !== 'number' && typeof percentage !== 'string') {
    return null;
  }
  return (
    <div className="bt-progress-bar">
      <div
        className="progress-bar-inner"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}
