/* global gapi */

import { useEffect, createContext, useState } from 'react';
import AWS from 'aws-sdk';

export const Context = createContext({ isSignedIn: false, isProcessing: true });
export const Provider = ({ children }) => {
  const [{ isSignedIn, isProcessing }, setSignInState] = useState({
    isSignedIn: false,
    isProcessing: true,
  });
  const [userProfile, setUserProfile] = useState({});
  useEffect(() => {
    AWS.config.region = 'ap-northeast-1'; // Region
    window?.addEventListener('on-sign-in', (e) => {
      if (e?.detail?.isSignedIn) {
        const authInstance = gapi.auth2.getAuthInstance();
        const googleUser = authInstance.currentUser.get();
        const authResponse = googleUser.getAuthResponse();
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'ap-northeast-1:dfa7c695-1198-4779-aaa2-e254d3487234',
          Logins: {
            'accounts.google.com': authResponse.id_token,
          },
        });
        AWS.config.credentials.get((err) => {
          if (!err) {
            setUserProfile(googleUser.getBasicProfile());
            setSignInState({
              isSignedIn: true,
              isProcessing: false,
            });
          }
        });
      } else {
        setUserProfile({});
        setSignInState({
          isSignedIn: false,
          isProcessing: false,
        });
      }
    });
  }, [setSignInState]);

  const userData = {
    isSignedIn,
    isProcessing,
    googleUser: userProfile,
  };
  return <Context.Provider value={userData}>{children}</Context.Provider>;
};
