import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { ProgressSection } from './ProgressBar.js';
import BookPage from '../Pages/BookPage';
import './BookList.css';

function BookListEntry({ amount, cname, name, shortName, record, pushPage }) {
  const hasReadAmount = Object.values(record).filter((value) => !!value).length;
  const progress = Math.round((hasReadAmount / amount) * 10000) / 100;
  return (
    <li
      className="book-list-entry"
      onClick={() => pushPage(BookPage, 'book-page', { bookId: shortName })}
    >
      <div className="book-list-entry-container">
        <h3 className="book-list-entry-title">{cname}</h3>
        <span className="book-list-entry-subtitle">{name}</span>
        <div className="progress-bar-container-overwrite">
          <ProgressSection percentage={progress} />
        </div>
        <FontAwesomeIcon
          className="book-list-entry-arrow"
          icon={faChevronRight}
          size="2x"
          color="#e5e9f2"
        />
      </div>
    </li>
  );
}

export default function BookList({ bookListData, records = {}, pushPage }) {
  if (!bookListData || bookListData.length === 0) {
    return <div className="book-list-placeholer">找不到書卷</div>;
  }
  return (
    <div className="book-list">
      <ul className="book-list-ul">
        {bookListData &&
          bookListData.map &&
          bookListData.map((book) => {
            return (
              <BookListEntry
                key={book.name}
                {...book}
                record={records[book.shortName] || {}}
                pushPage={pushPage}
              />
            );
          })}
      </ul>
    </div>
  );
}
