/*global ga */
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { Context as UserContext } from '../context/UserContext';
import BookContentPage from '../Pages/BookContentPage';
import './BookRecordEntry.css';

function sendOpenBibleSectionBeacon(bookName, sectionIndex) {
  if (!ga) {
    return;
  }
  ga(() => {
    const allTracker = ga.getAll();
    if (!allTracker || allTracker.length < 1) {
      return;
    }
    const tracker = allTracker[0];
    tracker.send({
      hitType: 'event',
      eventCategory: 'openBibleSection',
      eventAction: 'click',
      eventLabel: `${bookName}-${sectionIndex}`,
    });
  });
}

function RecommendProgress({ progress }) {
  if (!progress) {
    return null;
  }
  const progressTime = new Date(progress);
  return (
    <div className="book-record-entry-progress">
      {progressTime.getMonth() + 1}月{progressTime.getDate()}日
    </div>
  );
}

function BookRecordEntry({
  index,
  hasRead,
  onClick,
  shortName,
  progress,
  shouldShowBibleContent,
  pushPage,
  bookCname,
}) {
  const userContext = useContext(UserContext);
  return (
    <div className="book-record-entry" onClick={onClick.bind(this, index)}>
      <div className={`book-record-entry-inner ${hasRead ? 'pressed' : ''}`}>
        {index}
        {shouldShowBibleContent && userContext.isSignedIn ? (
          <button
            className="book-record-entry-read-book"
            onClick={(e) => {
              e.stopPropagation();
              sendOpenBibleSectionBeacon(shortName, index);
              pushPage(BookContentPage, 'book-content-page', {
                bookName: shortName,
                bookCname: bookCname,
                sectionIndex: index,
              });
            }}
          >
            <FontAwesomeIcon icon={faGlasses} width="15px" />
          </button>
        ) : null}
      </div>
      <RecommendProgress progress={progress} />
    </div>
  );
}

export default BookRecordEntry;
