import React from 'react';
import useLocalStorage from './useLocalStorage';

const ONE_DAY = 24 * 60 * 60 * 1000; // ms

export default function useFrequency(frequencyDuration = ONE_DAY) {
  const [value, setValue] = useLocalStorage('add2home', 0);
  const now = new Date().getTime();
  const isOverFrequency = now > value;
  const resetTime = React.useCallback(() => {
    setValue(new Date().getTime() + frequencyDuration);
  }, [setValue, frequencyDuration]);
  return [isOverFrequency, resetTime];
}
