import React, { createContext } from 'react';
import useDataBase from '../utils/useDataBase';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const dataBaseApi = useDataBase();
  const recordContext = {
    records: dataBaseApi.records,
    userProfile: dataBaseApi.getUserProfile(),
    ...dataBaseApi,
  };
  return <Context.Provider value={recordContext}>{children}</Context.Provider>;
};
