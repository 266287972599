import React, { useContext, useEffect } from 'react';
import { Page } from 'react-onsenui';

import { ProgressSection } from '../components/ProgressBar';
import { Context as RecordContext } from '../context/recordContext';
import { Context as HeaderContext } from '../context/headerContext';
import { Context as UserContext } from '../context/UserContext';
import useCalculateProgress from '../utils/useCalculateProgress';
import useTodayProgress from '../utils/useTodayProgress';
import packageInfo from '../../package.json';
import StaticHeader from '../components/Mobile/StaticHeader';

import './HomePage.css';

const USER_NAME = '旅行家';

function Thumbnail({ src }) {
  return (
    <div className="thumbnail">
      <img
        referrerPolicy="no-referrer"
        width={src ? '100%' : '50pt'}
        alt="thumbnail"
        src={src ? src : '/thumbnail.png'}
      ></img>
    </div>
  );
}

function UserName({ userName }) {
  return (
    <span className="user-name">Hi，{userName ? userName : USER_NAME}！</span>
  );
}

function TotalProgressSection({ percentage = 0 }) {
  return (
    <section className="total-progress-section">
      <h2 className="total-progress-section-title">整體進度</h2>
      <ProgressSection percentage={percentage} />
    </section>
  );
}

function PartialProgressSection({
  newTestamentProgress,
  oldTestamentProgress,
}) {
  return (
    <section className="partial-progress-section">
      <h2 className="partial-progress-section-title">舊約</h2>
      <ProgressSection percentage={oldTestamentProgress} />
      <h2 className="partial-progress-section-title">新約</h2>
      <ProgressSection percentage={newTestamentProgress} />
    </section>
  );
}

export default function HomePage({ swipeToIndex }) {
  const { records } = useContext(RecordContext);
  const userContext = useContext(UserContext);
  const { setToNormal, resetTitle } = useContext(HeaderContext);
  const [totalProgress, oldTestamentProgress, newTestamentProgress] =
    useCalculateProgress(records);
  const [todayProgress] = useTodayProgress();

  useEffect(() => {
    resetTitle && resetTitle();
    setToNormal && setToNormal();
  });

  const userName = userContext?.googleUser?.getName?.();
  const userImageSrc = userContext?.googleUser?.getImageUrl?.();

  return (
    <Page renderToolbar={() => <StaticHeader title="Bible Traveler" />}>
      <div className="home-container">
        <Thumbnail src={userImageSrc} />
        <UserName userName={userName} />
        <TotalProgressSection percentage={totalProgress} />
        <PartialProgressSection
          newTestamentProgress={newTestamentProgress}
          oldTestamentProgress={oldTestamentProgress}
        />
        <section className="today-progress">
          <h3>今日推薦進度</h3>
          <ul>
            {todayProgress.map((progress) => {
              const [bookName, ...sectionNumbers] = progress;
              return (
                <li key={bookName} className="today-progress-entry">
                  {bookName}: {sectionNumbers.join(',')}
                </li>
              );
            })}
          </ul>
        </section>
        <button className="start-to-record-btn" onClick={() => swipeToIndex(1)}>
          開始記錄
        </button>
        <span className="pkg-version">ver. {packageInfo.version}</span>
      </div>
    </Page>
  );
}
