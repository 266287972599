/*global ga */

function sendBeacon(type, data) {
  if (typeof ga !== 'undefined' && typeof window !== 'undefined') {
    ga(() => {
      const allTracker = ga.getAll();
      if (!allTracker || allTracker.length < 1) {
        return;
      }
      const tracker = allTracker[0];
      tracker.set(data.name, data.content);
      //   tracker.set('page', window.location.pathname + window.location.search);
      tracker.send(type);
      //   tracker.send('pageview');
    });
  }
}

export default sendBeacon;
