import React from 'react';
import { toast, Flip } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import AddToHomeIOS from './components/AddToHomeIOS';
import AddToHomeAndroid from './components/AddToHomeAndroid';

// Pages
import HomePage from './Pages/MobileMainPage';

// Context
import { Provider as HeaderContextProvider } from './context/headerContext';
import { Provider as RecordContextProvider } from './context/recordContext';
import { Provider as ToastContextProvider } from './context/ToastContext';
import { Provider as UserProvider } from './context/UserContext';

toast.configure({
  position: 'bottom-left',
  autoClose: 3000,
  hideProgressBar: true,
  className: 'toast-class',
  transition: Flip,
});

function App() {
  return (
    <HeaderContextProvider>
      <RecordContextProvider>
        <ToastContextProvider>
          <UserProvider>
            <div className="App">
              <HomePage />
              <AddToHomeIOS />
              <AddToHomeAndroid />
            </div>
          </UserProvider>
        </ToastContextProvider>
      </RecordContextProvider>
    </HeaderContextProvider>
  );
}

export default App;
