import React, { useState, useContext, useEffect } from 'react';
import { Page } from 'react-onsenui';
import SearchBar from '../components/SearchBar.js';
import BookList from '../components/BookList.js';
import StaticHeader from '../components/Mobile/StaticHeader';
import useFilteredBookList from '../utils/useFilteredBookList.js';
import useBookList from '../utils/useBookList';
import { Context as HeaderContext } from '../context/headerContext';
import { Context as RecordContext } from '../context/recordContext';
import './BookListPage.css';

export default function BookListPage({ pushPage }) {
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [bookList] = useBookList();
  const [filteredBookList] = useFilteredBookList(searchKeyWord, bookList);
  const headerContext = useContext(HeaderContext);
  const { setToNormal, resetTitle } = headerContext;
  const { records } = useContext(RecordContext);
  useEffect(() => {
    resetTitle && resetTitle();
    setToNormal && setToNormal();
  }, [setToNormal, resetTitle]);
  return (
    <Page renderToolbar={() => <StaticHeader title="讀經紀錄" />}>
      <div className="book-list-page">
        <SearchBar searchKeyWordCallback={setSearchKeyWord} />
        <BookList
          bookListData={filteredBookList}
          records={records}
          pushPage={pushPage}
        />
      </div>
    </Page>
  );
}
